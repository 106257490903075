import React from 'react';
import { Row, Col } from 'react-bootstrap';
import HomeAsider from '../images/home-asider.png';

const PersonalMessageRow = () => {
  return (
    <Row className='personal-message-row'>
      <Col className='personal-message-picture-col' lg={6}>
        <img className='personal-message-picture' src={HomeAsider} alt='Dr. Toni' />
      </Col>
      <Col lg={6}>
        <p className='personal-message-header'>
          About Dr. Toni
        </p>
        <div className='personal-message-content'>
          <p >
            Dr. Toni is an internationally bestselling <a href='https://courses.boldandbalancedcoaching.com/products/courses/view/38' target='_blank' rel="noreferrer">co-author</a>, presenter, certified executive life coach, psychotherapist, former behavior specialist and proud mom. She is also founder of Bold & Balanced Living, with Toni Lynn, PhD and <a href='https://www.authenticallymepsychotherapy.com' target='_blank' rel="noreferrer">Authentically Me Psychotherapy</a>. Her over a decade of experience studying human behavior and psychology, working as a mental health clinician, and experience supporting individuals, leaders and team members to bridge communication gaps and enhance performance, clearly sets her apart from all other coaches.
          </p>
          <p>
            Having navigated toxic work and relationship environments, mental health challenges, and overcome burnout herself, Dr. Toni offers an unprecedented level of personal understanding and premium-level, professional support.
          </p>
          <p>
            Dr. Toni has dedicated her personal and professional life to inspire and enact meaningful change in the lives of individual leaders as well as the systems they support- be it at work or at home. Employing practical yet powerful techniques, she effectively teaches all things balance, wellness, burnout prevention, leadership and impact.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default PersonalMessageRow;